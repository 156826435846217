<template>
   <div class="login-main">
      <nav-bar></nav-bar>
      <section class="fixed-box faqs-start-main box-flex">
         <div class="container review-main text-capitalize position-relative faq-main box-flex__wrapper">
            <div class="row">
               <div class="col-md-12 p-0">
                  <div class="center-card">
                     <div class="center-card-box | p-3 border-bottom">
                        <div class="resturant-details">
                           <h4 class="text-start">{{ $t('FAQs') }}</h4>
                        </div>
                     </div>
                     <div class="center-card-box | p-3">
                        <div class="accordion" id="accordionExample" v-if="Faqs.length > 0">
                           <div class="card" v-for="(faq, index) in Faqs" :key="index">
                              <div class="card-header p-0" :id="`headingOne-${index}`">
                                 <h2 class="mb-0 ">
                                    <button class="btn btn-link btn-block text-start collapsed d-flex w-100 fw-medium p-3"
                                       type="button" data-bs-toggle="collapse" :data-bs-target="`#collapseOne-${index}`"
                                       aria-expanded="true" :aria-controls="`collapseOne-${index}`">
                                       {{ faq.question }}
                                    </button>
                                 </h2>
                              </div>
                              <div :id="`collapseOne-${index}`" :class="index == 0 ? 'collapse show' : 'collapse'"
                                 :aria-labelledby="`headingOne-${index}`" data-bs-parent="#accordionExample">
                                 <div class="card-body">
                                    {{ faq.answer }}
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div v-else class="offers order-offers">
                           <div class="text-center my-2" v-if="loading">
                              <b-spinner class="align-middle"></b-spinner>
                           </div>
                           <p v-else>{{ $t('No Faq Found.') }}</p>
                        </div>
                     </div>
                  </div>


               </div>
            </div>
         </div>
      </section>
      <Footer></Footer>
   </div>
</template>


<script>
import NavBar from '../components/NavBar.vue'
import { mapActions, mapGetters } from "vuex";
import Footer from '../components/Footer.vue';

export default {
   name: "FAQ",
   components: { NavBar, Footer },
   data() {
      return {
         loading: true,
         Faqs: [],
      }
   },
   mounted() {
      this.getFaq();
   },
   computed: {
      ...mapGetters("faq", ["faqs"])
   },
   methods: {
      ...mapActions("faq", ["updateFaq"]),

      getFaq() {
         this.loading = true;
         this.updateFaq({
            type: 'customer',
            vendor_id: this.$auth.getVendorId(),
            is_langauge: this.$store.state.lang.locale
         }).then(data => {
            if (data.code == 200) {
               this.loading = false;
               this.Faqs = data.Result;
            } else {
               this.loading = false;
               this.Faqs = [];
            }
         })
      }
   }
}
</script>